import React, { useEffect, useState } from "react";
import '../../css/Pages/CatalogPage.css';
import { axiosAsk, getCookie } from "../../api/api";

import Catalog from "../Parts/Catalog";
import AccHeader from "../Parts/AccHeader";
import CatalogFilterItem from "../UI/CatalogFilterItem/CatalogFilterItem";
import ErrorPage from "./ErrorPage";
import FilterPart from "../Parts/FilterPart";
import SortItem from "../Parts/SortItem";
import { WarningAlert } from "../UI/Alert/Alert";

function CatalogPage({prods, setProds, prodsIds, setProdsIds, catalogPosition, setCatalogPosition, scrollPosition, setScrollPosition, searchText, getScrollParent}){
    const[error, setError] = useState(false);
    const[alertText, setAlertText] = useState('');

    const[filterMode, setFilterMode] = useState(false);
    const[wait, setWait] = useState(false);

    const[sort, setSort] = useState(null);
    const[isManGender, setIsManGender] = useState(null);
    const[costMin, setCostMin] = useState(null);
    const[costMax, setCostMax] = useState(null);
    const[typeNames, setTypeNames] = useState(null);
    const[brandNames, setBrandNames] = useState(null);


    function getFilteredList(){
        setTypeNames([getCookie('filter')]);
        document.cookie = `filter=; path=/; max-age=-1`;
    }
    function getList(reset = false){
        // if(scrollPosition > 1000){
        //     return
        // }
        
        setWait(true);
        let text_sort = '';
        if(sort === 0 || sort === null){
            text_sort = 3;
        }else if(sort === 1){
            text_sort = 1;
        }else if(sort === 2){
            text_sort = 2;
        }

        if(reset && searchText != null){
            axiosAsk({
                chat_id:getCookie('user_id'),
                oper:'get_list_by_search',
                ask:(searchText != null ?searchText :''),
                categories:[],
                brands:[],
                sort_type:text_sort,
                gen:true,
                cost_min:0, 
                cost_max:10000000000,
                flag:true,
            })
            .then(response => {
                if(response.data['articles'].length == 0){
                    setAlertText('Не найдено товаров, подходящих под ваш запрос')
                }else{
                    setProdsIds(response.data['articles'])
                }
                setWait(false)
            })
            .catch(response => {setError(true); console.error(response)})
        }else{
            axiosAsk({
                chat_id:getCookie('user_id'),
                oper:'get_list_by_search',
                ask:(searchText != null ?searchText :''),
                categories:(typeNames == null ?[] :typeNames),
                brands:(brandNames == null ?[] :brandNames),
                sort_type:text_sort,
                gen:(isManGender == null ?true :!isManGender),
                cost_min:(costMin == null ?0 :costMin), 
                cost_max:(costMax == null || costMax == 0 ?10000000000 :costMax),
                flag:true,
            })
            .then(response => {
                if(response.data['articles'].length == 0){
                    setAlertText('Не найдено товаров, подходящих под ваш запрос')
                }else{
                    setProdsIds(response.data['articles'])
                }
                setWait(false)
            })
            .catch(response => {setError(true); console.error(response)})
        }
    }

    useEffect(() => {
        getList()
        // checkFilters()
    }, [])



    function setCatalogFiter(checked, value, groupeName){
        if(checked){
            if(groupeName == 'type'){
                setTypeNames([value])
            }else if(groupeName == 'brand'){
                if(brandNames == undefined){
                    setBrandNames([value])
                }else if(brandNames.findIndex(item => item == value) == -1){
                    setBrandNames([...brandNames, value])
                }
            }
        }else{
            if(groupeName == 'type'){
                if(typeNames != undefined && typeNames.findIndex(item => item == value) != -1){
                    let newArr = []
                    typeNames.forEach(item => {
                        if(item != value){
                            newArr.push(item)
                        }
                    })
                    setTypeNames(newArr)
                }
            }else if(groupeName == 'brand'){
                if(brandNames != undefined && brandNames.findIndex(item => item == value) != -1){
                    let newArr = []
                    brandNames.forEach(item => {
                        if(item != value){
                            newArr.push(item)
                        }
                    })
                    setBrandNames(newArr)
                }
            }
        }
    }
    function ApplyFilters(cost_min, cost_max, is_man, types_arr, brands_arr){
        // localStorage.setItem('costMin', cost_min == undefined ?'0' :String(cost_min))
        // localStorage.setItem('costMax', cost_max == undefined ?'10000000000' :String(cost_max))
        // localStorage.setItem('gender', is_man == undefined ?'true' :String(is_man))
        // localStorage.setItem('typesArr', types_arr == undefined ?JSON.stringify(undefined) :JSON.stringify(types_arr))
        // localStorage.setItem('brandsArr', brands_arr == undefined ?JSON.stringify(undefined) :JSON.stringify(brands_arr))
    
        setFilterMode(false)
        setCostMin(cost_min)
        setCostMax(cost_max)
        setIsManGender(is_man)
        setTypeNames(types_arr)
        setBrandNames(brands_arr)
    }
    function ResetFilters(){
        setFilterMode(false)
        setCostMin(null)
        setCostMax(null)
        setIsManGender(null)
        setTypeNames(null)
        setBrandNames(null)
    }
    useEffect(() => {
        if(costMax !== null){
            getList()
        }
    }, [costMax])
    useEffect(() => {
        if(searchText != null){
            ResetFilters()
            getList(true)
        }
    }, [searchText])
    useEffect(() => {
        if(typeNames != null){
            getList()
        }
    }, [typeNames])
    useEffect(() => {
        if(brandNames != null){
            getList()
        }
    }, [brandNames])
    useEffect(() => {
        if(isManGender !== null){
            getList()
        }
    }, [isManGender])


    function checkFilters(){
        if(getCookie('filter') != undefined && getCookie('filter') != ''){
            getFilteredList()
            return
        }

        setCostMin(Number(localStorage.getItem('costMin')))
        setCostMax(localStorage.getItem('costMax') == '' || localStorage.getItem('costMax') == undefined ?0 :Number(localStorage.getItem('costMax')))
        setIsManGender(localStorage.getItem('gender') === 'false' ?false :true)
        setTypeNames(JSON.parse(localStorage.getItem('typesArr')))
        setBrandNames(JSON.parse(localStorage.getItem('brandsArr')))
    }




    if(error){
        return(
            <ErrorPage/>
        )
    }

    return(
        <div className="catalog_page">
            <WarningAlert setNull={setAlertText}>{alertText}</WarningAlert>
            {filterMode
                ?<FilterPart
                    Apply={ApplyFilters}
                    setError={setError}
                    setFilterMode={setFilterMode}
                    costMin={costMin}
                    costMax={costMax}
                    isMan={isManGender}
                    checkedTypes={typeNames}
                    checkedBrands={brandNames}
                />
                :<></>
            }
            <div className="cat_header">
                <AccHeader>КАТАЛОГ</AccHeader>
            </div>
            <div className="first_cat_filter">
                <CatalogFilterItem type={'type'} Checked={setCatalogFiter} imgsrc={'./img/item_photos/shoes.png'} value='shoes' groupeName='type'>ОБУВЬ</CatalogFilterItem>
                <CatalogFilterItem id='small-tshirt-filter' type={'type'} Checked={setCatalogFiter} imgsrc={'./img/item_photos/tshort.png'} value='tshorts' groupeName='type'>ФУТБОЛКИ</CatalogFilterItem>
                <CatalogFilterItem id='small-acces-filter' type={'type'} Checked={setCatalogFiter} imgsrc={'./img/item_photos/accessories.png'} value='accessories' groupeName='type'>АКСЕССУАРЫ</CatalogFilterItem>
            </div>
            <div className="cutter"></div>
            <div className="second_cat_filter">
                <CatalogFilterItem type={'brand'} Checked={setCatalogFiter} imgsrc={'./img/item_photos/nike_logo.png'} value={'Nike'} groupeName={'brand'}>NIKE</CatalogFilterItem>
                <CatalogFilterItem type={'brand'} Checked={setCatalogFiter} imgsrc={'./img/item_photos/adidas_logo.png'} value={'Adidas'} groupeName={'brand'}>ADIDAS</CatalogFilterItem>
                <CatalogFilterItem type={'brand'} Checked={setCatalogFiter} imgsrc={'./img/item_photos/new_balance_logo.png'} value={'New Balance'} groupeName={'brand'}>NEW BALANCE</CatalogFilterItem>
                <CatalogFilterItem type={'brand'} Checked={setCatalogFiter} imgsrc={'./img/item_photos/puma_logo.jpg'} value={'Puma'} groupeName={'brand'}>PUMA</CatalogFilterItem>
            </div>
            <div className="cutter"></div>
            <SortItem availSort={sort} setAvailSort={setSort} setFilterMode={setFilterMode} getList={getList}/>
            <Catalog
                getScrollParent={getScrollParent}
                prodsIds={prodsIds}
                prods={prods}
                setProds={setProds}
                catalogPosition={catalogPosition}
                setCatalogPosition={setCatalogPosition}
                scrollPosition={scrollPosition}
                setScrollPosition={setScrollPosition}
                setWait={setWait}
                setError={setError}
            />
            {wait
                ?<div className="wait_icon"></div>
                :<></>
            }
        </div>
    )
}



export default CatalogPage;