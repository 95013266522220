import React from "react";
import { useEffect , useState } from "react";
import '../../css/Pages/LoginPage.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { axiosAsk, getCookie } from "../../api/api";

import H1 from "../UI/H1/H1";
import MainBut from "../UI/MainBut/MainBut";
import AccHeader from "../Parts/AccHeader";
import TextBut from "../UI/TextBut/TextBut";
import ErrorPage from "./ErrorPage";
import PhoneInput from "../Parts/PhoneInput";


function LoginPage(){
    const[regData, setRegData] = useState({name:'', sur:'', phone:'', mail:'', code:''})
    const[errData, setErrData] = useState('');
    const[qrsrc, setQrsrc] = useState(null);
    const[loud, setLoud] = useState(0);
    const[updateStat, setUpdateStat] = useState(0);
    const[timer, setTimer] = useState(31);

    const[error, setError] = useState(false);
    const[windowWidth, setWindowWidth] = useState(0);


    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const paramValue = params.get('do');
    const navigate = useNavigate();



    useEffect(() => {
        setErrData('')

        if((getCookie('reg_part') === undefined || getCookie('reg_part') === 'one') && paramValue === 'registr'){
            document.cookie = 'reg_part=one; path=/login; max-age=157680000'
            document.getElementById('part1').style.display = 'block'
            document.getElementById('part2').style.display = 'none'
        }else if(getCookie('reg_part') === 'two'){
            document.getElementById('part1').style.display = 'none'
            document.getElementById('part2').style.display = 'block'
        }

        if((paramValue === 'registr' || paramValue === 'login') && qrsrc === null){
            setLoud(1)
            setTimer(30)
            axiosAsk({
                oper:'get_login_link',
                flag:true
            })
            .then(response => {
                setLoud(0)
                setQrsrc({img:`data:image/png;base64,${response.data['image']}`, link:response.data['link'], login_id:response.data['login_id']})
            })
            .catch(error => {console.error(error); setError(true)})
        }
    }, [paramValue])

    useEffect(() => {
        document.getElementById('scroll_wrapper').scrollTo({top: 0});
        if(getCookie('enter') === 'login'){
            navigate('/')
        }
        
        if((getCookie('reg_part') === undefined || getCookie('reg_part') === 'one') && paramValue === 'registr'){
            document.cookie = 'reg_part=one; path=/login; max-age=157680000'
            document.getElementById('part1').style.display = 'block'
            document.getElementById('part2').style.display = 'none'
        }else if(getCookie('reg_part') === 'two'){
            document.getElementById('part1').style.display = 'none'
            document.getElementById('part2').style.display = 'block'
        }
        // setTimer(timer-1)

        function handleResize(){
            setWindowWidth(windowWidth.innerWidth)
        }
        window.addEventListener('resize', handleResize);
        window.addEventListener('keypress', KeyPress);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('keypress', KeyPress);
        }
    }, [])


    function KeyPress(e){
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
            const inputs = document.getElementsByTagName('input');
            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i] === document.activeElement) {
                if (i < inputs.length - 1) {
                    inputs[i + 1].focus();
                    break;
                }
                }
            }
        }
    }


    function LogUp(){   //РЕГИСТРАЦИЯ
        document.cookie = 'reg_part=one; path=/login; max-age=157680000'
        document.cookie = 'enter=login; path=/; max-age=157680000'
        axiosAsk({
            oper:'set_account_info_by_id',
            chat_id:getCookie('user_id'),
            flag:true,
            name:regData.name,
            surname:regData.sur,
            phone:regData.phone,
            address:regData.mail
        })
        .catch(response => {setError(true); console.error(response)})
        navigate('/profile')
    }
    useEffect(() => {
        if(qrsrc !== null){
            setUpdateStat(1)
        }
    }, [qrsrc])
    useEffect(() => {
        if(updateStat !== 0){
            setLoud(1)
            axiosAsk({oper:'apply_login', login_id:qrsrc.login_id, flag:true})
            .then(response => {
                if(response.data['ans'] === true){
                    setLoud(0)
                    if(paramValue === 'login'){
                        document.cookie = 'user_id=' + response.data['chat_id'] + '; path=/; max-age=157680000'
                        document.cookie = 'enter=login; path=/; max-age=157680000'
                        navigate('/profile')
                    }else if(paramValue === 'registr'){
                        setErrData('')
        
                        document.cookie = 'user_id=' + response.data['chat_id'] + '; path=/; max-age=157680000'
                        document.cookie = 'reg_part=two; path=/login; max-age=157680000'
        
                        document.getElementById('part1').style.display = 'none'
                        document.getElementById('part2').style.display = 'block'

                        // axiosAsk({oper:'reg_user', chat_id:response.data['chat_id'], flag:true})
                        // .catch(response => {setError(true); console.log(response)})
                    }
                }else{
                    setTimeout(() => {
                        if(updateStat < 15){
                            setUpdateStat(updateStat+1)
                        }else{
                            setErrData('Для повторения попытки обновите страницу')
                        }
                    }, 2000);
                }
            })
            .catch(error => {setError(true); console.error(error)})
        }
    }, [updateStat])

    useEffect(() => {
        if(timer > 0){
            setTimeout(() => {
                setTimer(timer-1)
            }, 1000)
        }
    }, [timer])



    if(error){
        return(
            <ErrorPage/>
        )
    }

    if(paramValue === 'login'){
        return(
            <div className="log_part">
                <AccHeader>ВОЙТИ В АККАУНТ</AccHeader>
                <div className="tg_part">
                    <div className="first_text">Перейдите в нашего бота при помощи</div>
                    <div className="qr_img">
                        {qrsrc !== null
                            ?<img src={qrsrc.img}/>
                            :<></>
                        }
                    </div>
                    <div className="word_part">или</div>
                    {qrsrc !== null && timer != 0
                        ?<div className="tg_button" onClick={() => window.open(qrsrc.link, '_blank').focus()}>ПЕРЕЙТИ</div>
                        :<div className="link_filler">по ссылке</div>
                    }
                </div>
                <div className="interesting_text">После подтверждения вы автоматически войдёте в свой аккаунт</div>
                {errData != ''
                    ?<h3>{errData}</h3>
                    :<><div className="loader_icon"></div>
                    <div className="timer">{timer < 10 ?`00:0${timer}` :`00:${timer}`}</div></>
                }
                <TextBut mrgnTp="20px" onClick={() => navigate('/login?do=registr')}>Или зарегистрироваться</TextBut>
            </div>
        )
    }else if(paramValue === 'registr'){
        return(
            <div className="reg_part">
                <AccHeader>РЕГИСТРАЦИЯ</AccHeader>
                <div className="hideble_reg_block" id="part1">
                    <div className="tg_part">
                        <div className="first_text">Перейдите в нашего бота при помощи</div>
                        <div className="qr_img">
                            {qrsrc !== null
                                ?<img src={qrsrc.img}/>
                                :<></>
                            }
                        </div>
                        <div className="word_part">или</div>
                        {qrsrc !== null && timer != 0
                            ?<div className="tg_button" onClick={() => window.open(qrsrc.link, '_blank').focus()}>ПЕРЕЙТИ</div>
                            :<div className="link_filler">по ссылке</div>
                        }
                        <div className="interesting_text">После подтверждения вы автоматически продолжите регистрацию</div>
                        {errData != ''
                            ?<h3>{errData}</h3>
                            :<><div className="loader_icon"></div>
                            <div className="timer">{timer < 10 ?`00:0${timer}` :`00:${timer}`}</div></>
                        }
                        <TextBut mrgnTp='20px' onClick={() => navigate('/login?do=login')}>Или войти</TextBut>
                    </div>
                </div>
                <div className="hideble_reg_block" id="part2">
                    <div className="log_reg_input_part">
                        <div className="big">Успешно!</div>
                        <div className="small">А теперь, давайте познакомимся</div>
                        <input
                            type="text"
                            id="log_input"
                            placeholder="Имя"
                            value={regData.name}
                            onChange={(e) => setRegData({...regData, name:e.target.value})}
                        />
                        <input
                            type="text"
                            id="log_input"
                            placeholder="Фамилия"
                            value={regData.sur}
                            onChange={(e) => setRegData({...regData, sur:e.target.value})}
                        />
                        <PhoneInput phone={regData.phone} setPhone={setRegData} registr/>
                        <input
                            type="text"
                            id="log_input"
                            placeholder="Почта"
                            value={regData.mail}
                            onChange={(e) => setRegData({...regData, mail:e.target.value})}
                        />
                    </div>
                    {errData != ''
                        ?<h3>{errData}</h3>
                        :<></>
                    }
                    <MainBut id='not_log_but' onClick={LogUp} theme={'dark'}>ЗАВЕРШИТЬ</MainBut>
                </div>
            </div>
        )
    }else{
        return(
            <div className="logout_part">
                <div className="omg_smile"></div>
                <h1>Ой! Войдите в учётную запись!</h1>
                <div className="under_smile_text">Войдите в свой аккаунт или зарегистрируйтесь, чтобы посмотреть корзину и избранные</div>
                <div className="but_part">
                    <MainBut id='not_log_but' theme={'dark'} onClick={() => navigate('/login?do=registr')}>РЕГИСТРАЦИЯ</MainBut>
                    <MainBut id='not_log_but' theme={'dark'} onClick={() => navigate('/login?do=login')}>ВОЙТИ</MainBut>
                </div>
            </div>
        )
    }
}

export default LoginPage;