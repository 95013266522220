import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import HeaderBut from "../../UI/HeaderBut/HeaderBut";
import FilterPart from "../FilterPart";
import OutsideClickHandler from "react-outside-click-handler";
import SearchHistory from "../SearchHistory";
import { axiosAsk, getCookie } from "../../../api/api";

function Header({Apply}){
    const[searchMode, setSearchMode] = useState(false);
    const[search, setSearch] = useState('');
    const[filterMode, setFilterMode] = useState(false);
    const[history, setHistory] = useState(null);

    const[menuPosition, setMenuPosition] = useState(-95);

    const[buttonMargin, setButtonMargin] = useState(13);
    const[windowWidth, setWindowWidth] = useState(window.innerWidth);
    const[permit, setPermit] = useState(true)

    const navigate = useNavigate();

    useEffect(() => {
        if(windowWidth > 500){
            setPermit(true)
        }else{
            setPermit(false)
        }

        if(windowWidth <= 980){
            setButtonMargin(13)
        }else{
            setButtonMargin(50)
        }
    }, [windowWidth])
    useEffect(() => {
        if(searchMode){
            document.getElementsByTagName('input')[0].focus()
        }else{
            // document.getElementsByTagName('input')[0].blur()
            if(windowWidth < 980){
                setButtonMargin(13)
            }else{
                setButtonMargin(50)
            }
        }
    }, [searchMode])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }

        axiosAsk({
            oper:'get_ask_history',
            chat_id:getCookie('user_id'),
            flag:true,
        })
        .then(response => {
            setHistory(response.data['ask_list'])
        })
        .catch(error => console.error(error));

        window.addEventListener('keypress', KeyPress);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('keypress', KeyPress);
            window.removeEventListener('resize', handleResize);
        }
    }, [])


    function chengeFilterMode(){
        if(filterMode){
            setFilterMode(false)
        }else{
            setFilterMode(true)
        }
    }

    function KeyPress(e){
        if (e.key === 'Enter' || e.keyCode === 13) {
            goSearch()
        }
    }
    function goSearch(from_rec = false){
        document.getElementsByTagName('input')[0].blur()
        navigate('/')
        if(from_rec){
            Apply(document.getElementsByTagName('input')[0].value)
        }else{
            Apply(document.getElementsByTagName('input')[0].value)
        }
        setTimeout(() => {
            document.getElementById('scroll_wrapper').scrollTo({top: 700, behavior : "smooth"});
        }, 200)
        setSearchMode(false);
    }
    function setFullSearch(search){
        setSearch(search)
        goSearch()
    }
    function openMenu(){
        if(menuPosition == -95){
            setMenuPosition(70)
        }else{
            setMenuPosition(-95)
        }
    }

    if(searchMode && windowWidth < 750){
        return(
            <OutsideClickHandler onOutsideClick={() => setSearchMode(false)}>
                <header className="mobile_search_header">
                    <div className="return" onClick={() => setSearchMode(false)}></div>
                    <div className="search_bar_place">
                        <div className="search_bar">
                            <div className="search_icon" onClick={goSearch}></div>
                            <input
                                type="text"
                                placeholder="поиск"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className='close_icon' onClick={() => setSearch('')}></div>
                            {/* <div className="filter_icon" onClick={chengeFilterMode}></div> */}
                        </div>
                    </div>
                    {filterMode
                        ?<FilterPart setFilterMode={setFilterMode}/>
                        :<></>
                    }
                </header>
                <SearchHistory setSearch={setFullSearch} history={history} search={search}/>
            </OutsideClickHandler>
        )
    }else{
        return(
            <>
            <header>
                <div className="top_line">
                    <div className="side_help_butts">
                        {searchMode
                            ?<OutsideClickHandler onOutsideClick={() => setSearchMode(false)}><div className="search_part">
                                <div className="search_bar">
                                    <div className="search_icon" onClick={goSearch}></div>
                                    <input
                                        type="text"
                                        placeholder="поиск"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <div className='close_icon' onClick={() => setSearch('')}></div>
                                    {/* <div className="filter_icon" onClick={chengeFilterMode}></div> */}
                                </div>
                                <SearchHistory setSearch={setFullSearch} history={history} search={search}/>
                            </div></OutsideClickHandler>
                            :<div className="icon_div"><div className='search_icon' onClick={() => setSearchMode(true)}>Поиск</div></div>
                        }
                        {windowWidth >= 750
                            ?<>
                            <div className="icon_div"><div className='favor_icon' onClick={() => navigate('/favorites')}>Избранное</div></div>
                            <div className="icon_div"><div className='cart_icon' onClick={() => navigate('/cart')}>Корзина</div></div>
                            <div className="icon_div"><div className='acc_icon' onClick={() => navigate('/profile')}>Профиль</div></div>
                            </>
                            :<div className="icon_div"><div className='menu_icon' onClick={openMenu}>Меню</div></div>
                        }
                    </div>
                </div>
                {windowWidth > 500
                    ?<div className="bot_line">
                        <HeaderBut style={{marginRight: `${buttonMargin}px`}} to={'/'}>КАТАЛОГ</HeaderBut>
                        <HeaderBut style={{marginRight: `${buttonMargin}px`}} to={'/faq'}>FAQ</HeaderBut>
                    </div>
                    :<></>
                }
                <div className="logo_part">KODR</div>
            </header>
            <OutsideClickHandler onOutsideClick={() => (menuPosition == 70 ?setMenuPosition(-95) :<></>)}>
                <div className="header_menu" style={{top:menuPosition}}>
                    <div>
                        <div className="menu_but favor_icon" onClick={() => {navigate('/favorites'); openMenu()}}>Избранное</div>
                        <div className="menu_but cart_icon" onClick={() => {navigate('/cart'); openMenu()}}>Корзина</div>
                        <div className="menu_but acc_icon" onClick={() => {navigate('/profile'); openMenu()}}>Профиль</div>
                        {windowWidth <= 500
                            ?<div className="menu_but faq_icon" onClick={() => {navigate('/faq'); openMenu()}}>FAQ</div>
                            :<></>
                        }
                    </div>
                </div>
            </OutsideClickHandler>
            </>
        )
    }
}

export default Header;