import React, { useEffect, useState } from "react";
import '../../css/Search.css';
import { axiosAsk, getCookie } from "../../api/api";

function SearchHistory({setSearch, history, search}){
    const[recMode, setRecMode] = useState(false);
    const popular = ['nike', 'monarch', 'air', 'force', 'sb', 'dunk', 'kids', 'adidas', 'original', 'originals', 'apple', 'superstar', 'low', 'white', 'black', 'jordan', 'court', 'new balance', '327', '530', '574', '880', '410', '452', '608', 'alleyoop', 'samba', 'gazelle', 'puma', 'suede', 'smash', 'classic', 'platform', 'clyde', 'yeezy', 'boost', 'vans', 'old school', 'old', 'slip on', 'knu', 'knu school', 'style', 'asics', 'converse', 'women', 'fila', 'mlb', 'zoom', 'retro', 'balenciaga', 'gucci', 'louis vuitton', 'lv', 'swoosh', 'logo', 'supreme', 'sportswear']
    const[sameSearch, setSameSearch] = useState(['Nike', 'Air Force', 'Jordan', 'Adidas']);

    useEffect(() => {
        if(search == '' || search == null){
            setRecMode(false)
        }else{
            setRecMode(true)

            axiosAsk({
                oper:'get_predict_of_ask',
                ask_text:search,
                flag:true
            })
            .then(response => {
                setSameSearch(response.data['predict_list'])
            })
            .catch(error => console.error(error))
        }
    }, [search])

    return(
        <div className="search_history">
            {recMode
                ?sameSearch.map((item, index) => 
                    <div className="rec_element" onClick={() => setSearch(item)} key={index}>{item}</div>
                )
                :<>
                <div className="history_text">История</div>
                {history?.map((item, index) => 
                    <div className="history_element" onClick={() => setSearch(item)} key={index}>{item}</div>
                )}
                </>
            }
        </div>
    )
}

export default SearchHistory;